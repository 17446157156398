import Card from '../common/Card';
import { Instance, InstanceStatus } from '../../utils/types';
import { formatCurrency } from '../../utils/value';
import Button from '../common/Button';
import { openModal } from '../../slices/modals';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { ModalName } from '../modals';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { formatDuration, intervalToDuration } from 'date-fns';
import { displayModelName, findGPUPriceInstance } from '../../utils/instances';

// TODO: remove
const SuppliedInstanceCard = ({ instance }: { instance: Instance }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { gpu, gpuRamGB, cpu, gpuCount, storageCapacity } = instance;

  const { city = 'San Francisco', country = 'US' } = instance?.location || {};

  const region = 'North America';

  const { upload, download } = instance?.network || {};

  const status = instance.status || InstanceStatus.online;

  return (
    <Card
      key={instance.id}
      className="flex px-8 py-6 text-theme-neutral-700 gap-5 pb-5"
      onClick={(e) => {
        e.preventDefault();
        if (instance.reserved) return;
        dispatch(
          openModal({
            name: ModalName.SuppliedInstanceModal,
            props: { instance },
          })
        );
      }}
    >
      {/* quantity */}
      <div
        className={`mr-8 flex flex-col items-center ${
          status == InstanceStatus.busy ? 'animate-pulse' : ''
        }`}
      >
        <div
          className={`h-16 w-16 flex items-center justify-center rounded-full bg-theme-primary-100 text-theme-primary-600 text-[20px] font-bold`}
        >
          {gpuCount}X
        </div>
        <div className="flex flex-col items-center text-xs mt-2">
          <div>{status}</div>
        </div>
      </div>

      {/* gpu type */}
      <div className="flex flex-col flex-1 gap-2 items-start">
        <div className="text-2xl text-theme-primary-600 font-semibold">
          {displayModelName(gpu?.model)}
        </div>
        <div className="text-theme-neutral-600 text-sm">
          GPU RAM: {gpuRamGB}GB
        </div>
      </div>

      {/* storage */}
      <div className="flex-1 mb-5">
        <div>{storageCapacity}</div>
        <div className="text-theme-neutral-600 text-sm mt-2 whitespace-nowrap">
          <div className="flex justify-between max-w-32">
            <span>CPU Cores:</span>
            <span>{cpu?.cores || 16}</span>
          </div>
          <div className="flex justify-between max-w-32">
            <span>CPU Cache:</span>
            <span>{cpu?.cache || '39MB'}</span>
          </div>
        </div>
      </div>

      {/* location */}
      <div className="flex-1 ">
        <div className="flex-1">{`${region || country}${
          country || city ? `, ${country || city}` : ''
        }`}</div>
        <div className="flex flex-col mt-2 text-theme-neutral-600 text-sm whitespace-nowrap">
          <div className="flex items-center">
            <FiArrowUp className="inline mr-2 text-theme-primary-600" />
            {upload || 10} Mbps
          </div>
          <div className="flex items-center">
            <FiArrowDown className="inline mr-2 text-theme-primary-600" />
            {download || 100} Mbps
          </div>
        </div>
      </div>

      {/* duration */}
      <div className="flex-1">
        <div>
          Online{' '}
          {`${formatDuration(
            intervalToDuration({
              start: instance.availability?.startDate
                ? new Date(instance.availability?.startDate)
                : new Date(),
              end: new Date(),
            }),
            { format: ['days', 'hours', 'minutes', 'seconds'] }
          )}`}
        </div>
        <div className="flex flex-col mt-2 text-theme-neutral-600 text-sm ">
          <div>available</div>
        </div>
      </div>

      {/* price */}
      <div className="flex-1">
        <div className="table">
          <div className="text-theme-primary-600 font-bold text-lg mb-3">
            {formatCurrency(findGPUPriceInstance(instance))} / GPU /{' '}
            {instance.pricing?.price?.period || 'hr'}
          </div>
          <Button
            className="w-full"
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                openModal({
                  name: ModalName.SuppliedInstanceModal,
                  props: { instance },
                })
              );
            }}
          >
            Manage
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default SuppliedInstanceCard;
