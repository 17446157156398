import { useDispatch, useSelector } from 'react-redux';
import AuthForm, { AuthMode } from '../components/AuthForm';
import { useEffect, useState } from 'react';
import gpuImage from '../assets/gpu.png';
import Badge from '../components/common/Badge';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import Usage from '../components/Usage';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import {
  ModelUsage,
  fetchUsage,
  getUsage,
  getUsageLoading,
} from '../slices/usage';
import sumBy from 'lodash/sumBy';
import { AppDispatch } from '../store';
import PillLink from '../components/Dashboard/PillLink';
import { discordLink, supportEmail, twitterLink } from '../utils/constants';
import useUser, { UserStatus } from '../hooks/useUser';
import LoadingPage from '../components/LoadingPage';
import Layout from '../components/common/Layout';

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [mode, setMode] = useState<AuthMode>('signIn');
  const { user, status } = useUser();
  const userLoading = status === UserStatus.LoggingIn;
  const usageEnabled = useFeatureFlagEnabled('usage');
  const usage = useSelector(getUsage);
  const usageLoading = useSelector(getUsageLoading);
  const isLoading = userLoading || usageLoading;
  const totalUsage = sumBy(
    usage,
    (usageDatum: ModelUsage) =>
      usageDatum.completion_tokens +
      usageDatum.prompt_tokens +
      usageDatum.image_steps +
      usageDatum.audio_tokens
  );

  useEffect(() => {
    if (user) {
      dispatch(fetchUsage());
    }
  }, [user, dispatch]);

  return (
    // TODO: change padding to be more similar to how it is on other pages
    <Layout className="px-8 lg:px-8">
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="flex flex-col flex-1 items-center justify-center -mx-8">
          {user ? (
            <div className="flex flex-col flex-1 gap-6 px-6 lg:px-16 w-full">
              {usageEnabled && totalUsage > 0 ? (
                <Usage />
              ) : (
                <div className="flex items-center gap-32">
                  <div className="flex-1 text-theme-neutral-700">
                    <h1>Hi, {user?.displayName || 'there'}!</h1>
                    <div className="flex flex-col gap-4 mb-12">
                      <div className="flex items-center flex-wrap">
                        Welcome to the&nbsp;
                        <Badge text="alpha" className="mx-1" />
                        &nbsp;version of Hyperbolic AI dashboard!
                      </div>
                      <div>
                        We're thrilled to have you on board and eager to have
                        our dashboard help you make something truly special.
                      </div>
                      <div>
                        Your insights are invaluable to us as we evolve our
                        product. Please don't hesitate to share your thoughts
                        and feedback!
                      </div>
                      <div>Join us along the journey!</div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <PillLink
                        href={`mailto:${supportEmail}`}
                        text={supportEmail}
                        icon={
                          <MdOutlineAlternateEmail
                            size={24}
                            className="text-black"
                          />
                        }
                      />
                      <PillLink
                        href={twitterLink}
                        text="hyperbolic_labs"
                        icon={<FaXTwitter size={24} className="text-black" />}
                      />
                      <PillLink
                        href={discordLink}
                        text="hyperbolic-labs"
                        icon={<FaDiscord size={20} className="text-white" />}
                        iconContainerClassName="p-1.5 rounded-full bg-black"
                      />
                    </div>
                  </div>
                  <div
                    className="hidden lg:flex bg-no-repeat bg-contain bg-theme-neutral-100 rounded-xl h-[560px] w-[560px]"
                    style={{ backgroundImage: `url(${gpuImage})` }}
                  />
                </div>
              )}
            </div>
          ) : (
            <AuthForm
              className="bg-white border border-theme-primary-200 rounded-xl p-6 lg:p-8 mx-4 lg:mx-10 max-w-[512px]"
              mode={mode}
              onChangeMode={setMode}
            />
          )}
        </div>
      )}
    </Layout>
  );
};

export default Dashboard;
