import { Instance, InstanceStatus } from '../../utils/types';
import Button from '../common/Button';
import { openModal } from '../../slices/modals';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { ModalName } from '../modals';
import {
  displayModelName,
  displayPricePeriod,
  findGPUPriceInstance,
} from '../../utils/instances';
import { differenceInCalendarDays } from 'date-fns';
import Badge from '../common/Badge';
import InstanceCard from './InstanceCard';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { isMobile } from 'react-device-detect';
import { fullScreenStyles } from '../../utils/modals';
import { getIsAuthenticated } from '../../slices/auth';
import AuthButton from '../AuthButton';

// TODO: add back meaningful types instead of any
const MarketplaceInstanceCard = ({ instance }: { instance: Instance }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();
  const expandableCard = useFeatureFlagEnabled('expandable-instance-card');

  const { cpu, gpu, gpuRamGB, gpuCount, storageCapacity, ramCapacity } =
    instance || {};

  const { city } = instance?.location || {
    city: 'San Francisco',
  };

  const region = 'North America';
  const country = 'US';

  const { upload, download } = instance?.network || {};

  const endOfYear = new Date('12/31/2024');
  const max = differenceInCalendarDays(endOfYear, Date.now());
  const pricePeriod = instance?.pricing?.price?.period;

  return (
    <InstanceCard
      id={instance.id}
      gpuCount={gpuCount}
      gpuModelName={displayModelName(gpu?.model)}
      gpuRamGB={gpuRamGB}
      storageCapacity={storageCapacity}
      cpuCores={cpu?.cores}
      ramGB={ramCapacity}
      location={`${country || city}${
        region || country ? `, ${region || country}` : ''
      }`}
      upload={upload}
      download={download}
      maxDays={max}
      endOfYear={endOfYear}
      status={
        instance.status === InstanceStatus.starting
          ? instance.status
          : undefined
      }
      onClick={
        isMobile
          ? (e: any) => {
              e.preventDefault();
              dispatch(
                openModal({
                  name: ModalName.ConfirmRentModal,
                  props: { instanceId: instance.id },
                  styles: isMobile
                    ? fullScreenStyles
                    : {
                        content: { maxWidth: '700px' },
                      },
                })
              );
            }
          : null
      }
      gpuPrice={findGPUPriceInstance(instance)}
      pricePeriod={displayPricePeriod(pricePeriod)}
      actionButton={
        !isAuthenticated ? (
          <AuthButton className="max-w-32 text-sm py-1.5" />
        ) : (
          <Button
            className="w-full max-w-32 py-1.5"
            disabled={instance.reserved}
            onClick={(e) => {
              e.preventDefault();
              posthog?.capture('Rent Button Clicked', {
                instanceId: instance.id,
              });
              dispatch(
                openModal({
                  name: ModalName.ConfirmRentModal,
                  props: { instanceId: instance.id },
                  styles: isMobile
                    ? fullScreenStyles
                    : {
                        content: { maxWidth: '700px' },
                      },
                })
              );
            }}
          >
            {instance.reserved ? 'Reserved' : 'Rent'}
          </Button>
        )
      }
      expansion={
        expandableCard ? (
          <>
            <div className="flex gap-2 flex-1">
              <div>
                <div>Cluster name: {instance.clusterName}</div>
                <div>Node name: {instance.id}</div>
              </div>
              <div>
                <Badge
                  text="verified"
                  className="text-xs px-1.5 py-0 rounded-[4px] bg-theme-primary-50"
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div>CPU Model: {cpu?.model}</div>
              <div>Docker image: N/A</div>
            </div>
            <div className="flex flex-col flex-1">
              <div>Network Latency: N/A</div>
              <div>Interconnect Network: N/A</div>
            </div>
            <div className="w-40" />
          </>
        ) : null
      }
    />
  );
};

export default MarketplaceInstanceCard;
