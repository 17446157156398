import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface SupplySlice {
  step: number;
  operatingSystem: string;
  gpuBrand: string;
  gpuType: string;
  microInstalled: string;
  microInstallCompleted: string;
  hyperInstallCompleted: string;
  nodePrice: number;
  displayName: string;
  displayNameStatus: string;
  displayNameError: string;
}

const initialState: SupplySlice = {
  step: 0,
  operatingSystem: '',
  gpuBrand: '',
  gpuType: '',
  microInstalled: '',
  microInstallCompleted: '',
  hyperInstallCompleted: '',
  nodePrice: 0,
  displayName: '',
  displayNameStatus: '',
  displayNameError: '',
};

const supplySlice = createSlice({
  name: 'supply',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setOperatingSystem: (state, action) => {
      state.operatingSystem = action.payload;
    },
    setGpuBrand: (state, action) => {
      state.gpuBrand = action.payload;
    },
    setGpuType: (state, action) => {
      state.gpuType = action.payload;
    },
    setMicroInstalled: (state, action) => {
      state.microInstalled = action.payload;
    },
    setMicroInstallCompleted: (state, action) => {
      state.microInstallCompleted = action.payload;
    },
    setHyperInstallCompleted: (state, action) => {
      state.hyperInstallCompleted = action.payload;
    },
    setNodePrice: (state, action) => {
      state.nodePrice = action.payload;
    },
    setDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
    setDisplayNameStatus: (state, action) => {
      state.displayNameStatus = action.payload;
    },
    setDisplayNameError: (state, action) => {
      state.displayNameError = action.payload;
    },
  },
});

export const {
  setStep,
  setOperatingSystem,
  setGpuBrand,
  setGpuType,
  setMicroInstalled,
  setMicroInstallCompleted,
  setHyperInstallCompleted,
  setNodePrice,
  setDisplayName,
  setDisplayNameError,
  setDisplayNameStatus,
} = supplySlice.actions;

export const getSupply = (state: RootState) => state.supply;

export default supplySlice.reducer;
