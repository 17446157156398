import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSuppliedInstances,
  getFormattedSuppliedInstances,
} from '../slices/instances';
import useUser from '../hooks/useUser';
import { useEffect, useRef } from 'react';
import { AppDispatch } from '../store';
import useInterval from '../hooks/useInterval';

const SupplyList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const suppliedInstances = useSelector(getFormattedSuppliedInstances);
  const { user, authToken } = useUser();
  const authTokenRef = useRef('');

  useEffect(() => {
    if (authToken && authToken !== authTokenRef.current) {
      authTokenRef.current = authToken;
      dispatch(fetchSuppliedInstances());
    }
  }, [authToken, dispatch]);

  useInterval(() => {
    if (authToken) {
      dispatch(fetchSuppliedInstances());
    }
  }, 5000);
  return <div className="flex flex-col flex-1 w-full px-8"></div>;
};

export default SupplyList;
