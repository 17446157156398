import { ReactElement } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import Dashboard from './pages/Dashboard';
import Models from './pages/Models';
import ErrorPage from './pages/ErrorPage';
import ModelDetails from './pages/ModelDetails';
import NewMachineSupply from './pages/NewMachineSupply';
import NewMachineRequest from './pages/NewMachineRequest';
import Settings from './pages/Settings';
import BillingHome from './components/Settings/Billing/BillingHome';
import General from './components/Settings/General';
import Team from './pages/Team';
import ComingSoon from './components/Settings/ComingSoon';
import VerifyEmail from './pages/VerifyEmail';
import ComputeWrapper from './pages/ComputeWrapper';
import SupplyInstances from './pages/SupplyInstances';
import MyInstances from './pages/MyInstances';
import MarketplaceInstances from './pages/MarketplaceInstances';
import Support from './pages/Support';
import { FAQs } from './components/Support';
import FAQSection from './components/Support/FAQSection';
import Billing from './components/Settings/Billing/Billing';
import Base from './components/Base';
import SupplyList from './pages/SupplyList';
import SupplyOnboarding from './pages/SupplyOnboarding';

const FeatureFlaggedRoute = ({
  element,
  flag,
}: {
  element: ReactElement;
  flag: string;
}) => {
  const isSet = useFeatureFlagEnabled(flag);
  // todo: maybe display some non-empty fragment?
  return isSet ? element : <></>;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Base />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/models', element: <Models /> },
      {
        path: '/compute',
        element: <ComputeWrapper />,
        children: [
          { index: true, element: <MarketplaceInstances /> },
          {
            path: '/compute/rent',
            element: <MarketplaceInstances />,
          },
          {
            path: '/compute/instances',
            element: <MyInstances />,
          },
        ],
      },
      {
        path: '/supply',
        element: <SupplyInstances />,
      },
      { path: '/supply/list', element: <SupplyList /> },
      { path: '/supply/onboarding', element: <SupplyOnboarding /> },
      {
        path: '/models/:modelId/:mode?/:lang?',
        element: <ModelDetails />,
        handle: { goBack: '/models' },
      },
      {
        path: '/compute/new',
        element: <NewMachineSupply />,
        handle: { goBack: '/compute' },
      },
      {
        path: '/compute/new/request',
        element: <NewMachineRequest />,
        handle: { goBack: '/compute' },
      },
      {
        path: '/settings',
        element: <Settings />,
        children: [{ index: true, element: <General /> }],
      },
      {
        path: '/support',
        element: <Support />,
        children: [
          { index: true, element: <FAQs /> },
          { path: '/support/:sectionId', element: <FAQSection /> },
        ],
      },
      {
        path: '/team',
        element: <FeatureFlaggedRoute flag="team" element={<Team />} />,
      },
      {
        path: '/billing',
        element: <FeatureFlaggedRoute flag="billing" element={<Billing />} />,
        children: [
          {
            index: true,
            element: <BillingHome />,
          },
          {
            path: '/billing/newcard',
            element: <ComingSoon />,
          },
        ],
      },
      {
        path: '/verify-email',
        element: <VerifyEmail />,
      },
    ],
  },
]);

export default router;
