import Input from '../common/Input';
import useUser from '../../hooks/useUser';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { updateUserInfo } from '../../slices/auth';
import LoadingSpinner from '../common/LoadingSpinner';
import SaveIcon from '../common/icons/SaveIcon';
import HyperLink from '../common/HyperLink';
import InputSlider from '../common/InputSlider';
import {
  getSupply,
  setDisplayName,
  setDisplayNameError,
  setDisplayNameStatus,
  setNodePrice,
} from '../../slices/supply';

const FinalSetup = () => {
  const { userInfo } = useUser();
  const { nodePrice, displayName, displayNameStatus, displayNameError } =
    useSelector(getSupply);
  const displayNameLoading = displayNameStatus === 'loading';
  const dispatch = useDispatch<AppDispatch>();

  const inputValue = displayNameStatus ? displayName : userInfo?.name;

  const saveDisplayName = async () => {
    if (!displayNameError) {
      dispatch(setDisplayNameStatus('loading'));
      await dispatch(
        updateUserInfo({
          userId: userInfo?.id || '',
          update: { name: displayName },
        })
      );
      dispatch(setDisplayNameStatus(''));
    }
  };
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="text-lg font-semibold">
          What is the name of your company?
        </div>
        <div className="text-sm">
          Your brand will appear on the detail page of your supplied machines.
          You can also update it later in the{' '}
          <HyperLink to="/settings">Settings</HyperLink>.
        </div>
        <Input
          id="display-name-input"
          value={inputValue}
          containerClassName="flex-1 w-full max-w-[500px]"
          onChangeText={(text: string) => {
            setDisplayNameStatus('edited');
            if (!text) {
              dispatch(setDisplayName(''));
              dispatch(setDisplayNameError('Display name cannot be empty'));
            } else {
              dispatch(setDisplayNameError(''));
              dispatch(setDisplayName(text));
            }
          }}
          endIcon={
            displayNameLoading ? (
              <LoadingSpinner
                size={20}
                className="fill-theme-primary-600 text-theme-primary-300"
              />
            ) : inputValue !== userInfo?.name ? (
              <SaveIcon
                size={24}
                className="bg-theme-primary-600 text-white p-1 rounded-full cursor-pointer"
                onClick={saveDisplayName}
              />
            ) : undefined
          }
          endIconClassName="mt-2 right-3 flex items-center"
          error={!!displayNameError}
        />
        {displayNameError && (
          <div className="text-theme-danger-600 mb-3">{displayNameError}</div>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-lg font-semibold">
          Choose a price for your supplied node(s):
        </div>
        <div className="text-sm">
          We recommend starting from the lower end of the price range to boost
          actual utilization. You can always update pricing dynamically based on
          demand in the supplier dashboard. For more information on earnings and
          rewards, check the supplier documentation.
        </div>
        <div>
          <div className="font-semibold mb-4 text-black">Your on-demand price:</div>
          <InputSlider
            id="node-price"
            label="Price / GPU / hr"
            name="node-price"
            containerClassName="w-1/2"
            value={nodePrice}
            min={0}
            max={3}
            step={0.01}
            onChange={(value) => dispatch(setNodePrice(value))}
          />
        </div>
      </div>
    </div>
  );
};

export default FinalSetup;
