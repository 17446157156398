import RadioButton from './RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSupply,
  setHyperInstallCompleted,
  setMicroInstallCompleted,
  setMicroInstalled,
} from '../../slices/supply';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyButton from '../common/CopyButton';
import useUser from '../../hooks/useUser';
import { ReactNode } from 'react';
import useInterval from '../../hooks/useInterval';
import { AppDispatch } from '../../store';
import {
  fetchSuppliedInstances,
  getFormattedSuppliedInstances,
  getSuppliedInstancesInitialLoading,
  getUnpricedSupplied,
} from '../../slices/instances';
import LoadingSpinner from '../common/LoadingSpinner';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';

const SetupCode = ({ children }: { children: ReactNode }) => (
  <code className="px-2 py-0.5 bg-theme-neutral-50 border border-theme-neutral-300 rounded text-theme-primary-700 font-medium">
    {children}
  </code>
);

const SetupInstructions = () => {
  const { microInstalled, microInstallCompleted, hyperInstallCompleted } =
    useSelector(getSupply);
  const dispatch = useDispatch<AppDispatch>();
  const unpricedSupplied = useSelector(getUnpricedSupplied);
  const suppliedInitialLoading = useSelector(
    getSuppliedInstancesInitialLoading
  );
  const { userInfo } = useUser();
  const installMicroString = `sudo snap install microk8s --classic --channel=1.30

sudo usermod -a -G microk8s $USER # allow non-sudo use of microk8s command
newgrp microk8s # reload shell

microk8s start # boot the cluster
microk8s enable rbac # improve security
sudo microk8s enable community # add the community repos
microk8s enable argocd # install argocd
microk8s enable nvidia # install the nvidia GPU operator

microk8s kubectl create namespace hyperdos # create the necessary namespace`;

  const installHyperString = `sudo microk8s helm repo add hyperdos https://hyperboliclabs.github.io/Hyper-dOS
sudo microk8s helm install hyperdos hyperdos/hyperdos --version 0.0.1-alpha.4 --set
token=${userInfo?.api_key}`;

  useInterval(() => {
    if (hyperInstallCompleted === 'done') {
      dispatch(fetchSuppliedInstances());
    }
  }, 5000);

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="text-lg font-semibold">
          Do you have&nbsp;
          <SetupCode>MicroK8s</SetupCode>
          &nbsp;installed yet?
        </div>
        <div className="text-sm">
          MicroK8s is a lightweight Kubernetes distribution that is designed to
          run on local systems. We use it to set up your cloud. Don't worry if
          you are not familiar with it - it's hyper simple to install with less
          than 5 command lines!
        </div>
        <div className="flex flex-wrap gap-3">
          <RadioButton
            key="yes-microk8s"
            text="Yes, I have"
            selected={microInstalled === 'already'}
            onClick={() => dispatch(setMicroInstalled('already'))}
            otherSelected={!!microInstalled && microInstalled !== 'already'}
          />
          <RadioButton
            key="no-microk8s"
            text="Not yet"
            selected={microInstalled === 'not-yet'}
            onClick={() => dispatch(setMicroInstalled('not-yet'))}
            otherSelected={!!microInstalled && microInstalled !== 'not-yet'}
          />
        </div>
      </div>
      {microInstalled === 'not-yet' && (
        <div className="flex flex-col gap-4">
          <div className="text-lg font-semibold">
            Let's install&nbsp;
            <SetupCode>MicroK8s</SetupCode>
            &nbsp;in just one minute!
          </div>
          <div className="text-sm">
            Open the terminal and copy the following command lines to run.
          </div>
          <div>
            <div className="relative mt-2 md:mt-6">
              <CopyButton
                id="micro-installation-instructions"
                className="absolute top-8 right-6 text-theme-neutral-600 hover:text-theme-primary-600"
                copyText={installMicroString}
              />
            </div>
            <SyntaxHighlighter
              PreTag="div"
              // language={highlighterLang}
              style={oneLight}
              wrapLines
              wrapLongLines
              className="flex text-sm rounded-lg max-w-xs md:max-w-none border"
              customStyle={{ padding: '30px', overflow: 'unset' }}
            >
              {installMicroString}
            </SyntaxHighlighter>
          </div>
          <div className="flex flex-wrap gap-3">
            <RadioButton
              key="done-install"
              text="Done"
              selected={microInstallCompleted === 'done'}
              onClick={() => dispatch(setMicroInstallCompleted('done'))}
              otherSelected={
                !!microInstallCompleted && microInstallCompleted !== 'done'
              }
            />
            <RadioButton
              key="error-install"
              text="Something went wrong"
              selected={microInstallCompleted === 'error'}
              onClick={() => dispatch(setMicroInstallCompleted('error'))}
              otherSelected={
                !!microInstallCompleted && microInstallCompleted !== 'error'
              }
              className="border-theme-danger-500 hover:border-theme-danger-600 text-theme-danger-600"
            />
          </div>
        </div>
      )}
      {(microInstallCompleted === 'done' || microInstalled === 'already') && (
        <div className="flex flex-col gap-4">
          <div className="text-lg font-semibold">
            Now, one last step to install the Hyperbolic decentralized OS&nbsp;
            <SetupCode>HyperdOS</SetupCode>!
          </div>
          <div className="text-sm">
            Hyper-dOS is a decentralized operating system developed by
            Hyperbolic to manage and monitor your supplied machines.
          </div>
          <div>
            <div className="relative mt-2 md:mt-6">
              <CopyButton
                id="hyper-installation-instructions"
                className="absolute top-8 right-6 text-theme-neutral-600 hover:text-theme-primary-600"
                copyText={installHyperString}
              />
            </div>
            <SyntaxHighlighter
              PreTag="div"
              // language={highlighterLang}
              style={oneLight}
              wrapLines
              wrapLongLines
              className="flex text-sm rounded-lg max-w-xs md:max-w-none border break-all"
              customStyle={{
                padding: '30px',
                overflow: 'unset',
              }}
              codeTagProps={{
                className: 'break-all',
              }}
            >
              {installHyperString}
            </SyntaxHighlighter>
          </div>
          <div className="flex flex-wrap gap-3">
            <RadioButton
              key="done-hyper"
              text="Done"
              selected={hyperInstallCompleted === 'done'}
              onClick={() => {
                dispatch(setHyperInstallCompleted('done'));
                dispatch(fetchSuppliedInstances());
              }}
              otherSelected={
                !!hyperInstallCompleted && hyperInstallCompleted !== 'done'
              }
            />
            <RadioButton
              key="error-hyper"
              text="Something went wrong"
              selected={hyperInstallCompleted === 'error'}
              onClick={() => dispatch(setHyperInstallCompleted('error'))}
              otherSelected={
                !!hyperInstallCompleted && hyperInstallCompleted !== 'error'
              }
              className="border-theme-danger-500 hover:border-theme-danger-600 text-theme-danger-600"
            />
          </div>
        </div>
      )}
      {hyperInstallCompleted === 'done' && (
        <div className="flex flex-col gap-4">
          <div className="text-lg font-semibold">
            Perfect! Let's see if your device is securely connected to our
            network...
          </div>
          <div className="text-sm">
            We are actively checking for any new nodes that just joined with
            your supplier ID. This should only take a moment.
          </div>
          {suppliedInitialLoading && (
            <div className="flex items-center gap-4">
              <LoadingSpinner size={24} />
              <div>Checking in just a moment...</div>
            </div>
          )}
          {unpricedSupplied.length > 0 && (
            <div className="flex text-sm gap-2">
              <FaCircleCheck className="text-theme-primary-600" size={20} />
              Congrats! Your device (ID: {unpricedSupplied[0]?.id}) has
              successfully joined our network! Please proceed to the next step
              to set up your company name, pricing, and so on.
            </div>
          )}
          {unpricedSupplied.length === 0 && !suppliedInitialLoading && (
            <div className="flex text-sm gap-2">
              <FaCircleXmark className="text-theme-danger-600" size={20} />
              Sorry! We cannot find a recent cluster / node from your account.
              Please try again!
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SetupInstructions;
