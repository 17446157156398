import { useDispatch, useSelector } from 'react-redux';
import RentedInstanceCard from '../components/Compute/RentedInstanceCard';
import SuppliedInstanceCard from '../components/Compute/SuppliedInstanceCard';
import { AppDispatch } from '../store';
import stackedGPUs from '../assets/stacked-gpus.png';
import {
  fetchRentedInstances,
  fetchSuppliedInstances,
  getStatus,
  getFormattedRentedInstances,
  getFormattedSuppliedInstances,
  getRentedInstancesInitialLoading,
} from '../slices/instances';
import { useEffect, useRef } from 'react';
import LoadingSpinner from '../components/common/LoadingSpinner';
import useInterval from '../hooks/useInterval';
import { InstanceRental, ResponseStatus } from '../utils/types';
import useUser from '../hooks/useUser';
import useInstanceFilters from '../hooks/useInstanceFilters';

import {
  fetchInstanceHistory,
  getInstanceHistory,
  getInstanceHistoryInitialLoading,
} from '../slices/usage';
import InstanceHistoryCard from '../components/Compute/InstanceHistoryCard';
import SearchIcon from '../components/common/icons/SearchIcon';
import HyperLink from '../components/common/HyperLink';
import { usePostHog } from 'posthog-js/react';
import InstanceFilters from '../components/Compute/InstanceFilters';
import { useNavigate } from 'react-router-dom';

const MyInstances = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const rentedInstances = useSelector(getFormattedRentedInstances);
  const suppliedInstances = useSelector(getFormattedSuppliedInstances);
  const rentedInstancesInitialLoading = useSelector(
    getRentedInstancesInitialLoading
  );
  const instanceHistoryInitialLoading = useSelector(
    getInstanceHistoryInitialLoading
  );
  const status = useSelector(getStatus);
  const loading = status == ResponseStatus.Loading;
  const instanceHistory = useSelector(getInstanceHistory);

  const { sortedInstances: sortedRentedInstances, ...rentedFilters } =
    useInstanceFilters(
      rentedInstances,
      (rental: InstanceRental) => rental.instance,
      {
        sortFn: (rental: InstanceRental) => rental.start,
        sortOrder: 'desc',
        prioritizeOverPrice: false,
      }
    );

  const { user, authToken } = useUser();
  const authTokenRef = useRef('');

  useEffect(() => {
    if (authToken && authToken !== authTokenRef.current) {
      authTokenRef.current = authToken;
      dispatch(fetchRentedInstances());
      dispatch(fetchSuppliedInstances());
      dispatch(fetchInstanceHistory());
    }
  }, [authToken, dispatch]);

  useInterval(() => {
    if (authToken) {
      dispatch(fetchRentedInstances());
      dispatch(fetchSuppliedInstances());
      dispatch(fetchInstanceHistory());
    }
  }, 5000);

  useEffect(() => {
    if (!authToken) {
      return navigate('/compute');
    }
  }, [authToken, navigate]);

  // todo: switch to toggle to supplied instances as well, or is that on the supply page?

  if (
    !rentedInstancesInitialLoading &&
    rentedInstances.length === 0 &&
    !instanceHistoryInitialLoading &&
    instanceHistory.length === 0
  ) {
    return (
      <div className="flex flex-col items-center justify-center gap-10">
        <div>You don't have any rented instances yet!</div>
        <div className="flex">
          <HyperLink
            to="/compute/rent"
            variant="outline"
            className="flex items-center py-2"
            onClick={() => posthog?.capture('Find GPUs Clicked')}
          >
            <SearchIcon size={20} className="mr-3" />
            Find the GPUs You Need
          </HyperLink>
        </div>
        <img src={stackedGPUs} style={{ width: 696 }} />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full gap-6">
      <div className="flex items-center">
        <div className="text-lg font-semibold text-theme-neutral-700">
          Active
        </div>
        {loading && (
          <div className="ml-4">
            <LoadingSpinner className="w-4 h-4 mr-2" />
          </div>
        )}
      </div>
      {rentedInstances.length > 0 && (
        <InstanceFilters {...rentedFilters} theme="rented" />
      )}
      {rentedInstances?.length === 0 &&
        !!user &&
        !rentedInstancesInitialLoading && (
          <div className="flex flex-col gap-4">
            <div>You currently have no active instances.</div>
            <div className="flex">
              <HyperLink
                to="/compute/rent"
                variant="outline"
                className="flex items-center py-2"
                onClick={() => posthog?.capture('Find GPUs Clicked')}
              >
                <SearchIcon size={20} className="mr-3" />
                Find GPUs
              </HyperLink>
            </div>
          </div>
        )}
      {rentedInstancesInitialLoading && rentedInstances?.length === 0 && (
        <div className="mb-6">
          <div className="animate-pulse">
            <div className="flex flex-col gap-4">
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
            </div>
          </div>
        </div>
      )}
      {(sortedRentedInstances as InstanceRental[]).map((i) => (
        <RentedInstanceCard key={i.id} rental={i} />
      ))}
      {instanceHistory?.length > 0 && (
        <div className="text-lg font-semibold text-theme-neutral-700">
          History
        </div>
      )}
      {/* TODO: add filters */}
      {instanceHistoryInitialLoading && (
        <div className="mb-6">
          <div className="animate-pulse">
            <div className="flex flex-col gap-4">
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
              <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
            </div>
          </div>
        </div>
      )}
      {instanceHistory.map((i) => (
        <InstanceHistoryCard
          key={`${i.instance_name}-${i.started_at}`}
          instanceHistory={i}
        />
      ))}
      {/* TODO: add pagination */}
      {!!suppliedInstances.length && (
        <div className="flex flex-col gap-4">
          <div className="flex items-center text-lg font-semibold text-theme-neutral-700">
            Supplied Instances
            {loading && (
              <div className="ml-4">
                <LoadingSpinner className="w-4 h-4 mr-2" />
              </div>
            )}
          </div>
          {suppliedInstances.map((instance) => (
            <SuppliedInstanceCard key={instance.id} instance={instance} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MyInstances;
