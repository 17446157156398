import { useDispatch, useSelector } from 'react-redux';
import {
  getUserInfo,
  updateUserInfo,
} from '../../slices/auth';
import Input from '../common/Input';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import PublicKeyDropzone from '../common/PublicKeyDropzone';
import LockIcon from '../common/icons/LockIcon';
import APIKey from '../common/APIKey';
import ResetIcon from '../common/icons/ResetIcon';
import HyperLink from '../common/HyperLink';
import { openModal } from '../../slices/modals';
import { ModalName } from '../modals';
import { mobileStyles } from '../../utils/modals';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { SECOND } from '../../utils/constants';
import { FaCircleCheck } from 'react-icons/fa6';
import SaveIcon from '../common/icons/SaveIcon';
import { AppDispatch } from '../../store';
import LoadingSpinner from '../common/LoadingSpinner';

const General = () => {
  const userInfo = useSelector(getUserInfo);
  const [displayName, setDisplayName] = useState('');
  const [displayNameStatus, setDisplayNameStatus] = useState('');
  const [displayNameError, setDisplayNameError] = useState('');
  const isMarketplaceV2Enabled = useFeatureFlagEnabled('marketplace_v2');
  const dispatch = useDispatch<AppDispatch>();
  const [showCheckmark, setShowCheckmark] = useState(false);
  const displayNameLoading = displayNameStatus === 'loading';

  const inputValue = displayNameStatus ? displayName : userInfo?.name;

  const saveDisplayName = async () => {
    if (!displayNameError) {
      setDisplayNameStatus('loading');
      await dispatch(
        updateUserInfo({
          userId: userInfo?.id || '',
          update: { name: displayName },
        })
      );
      setDisplayNameStatus('');
    }
  };

  return (
    <div className="flex flex-col mb-6 gap-6 divide-y">
      <div className="flex flex-col gap-4 mb-2">
        <div className="text-lg font-semibold text-theme-neutral-700">
          Profile
        </div>
        <div className="flex flex-col">
          <div className="text-sm text-theme-neutral-700 font-medium w-28 mb-3">
            Display Name
          </div>
          <Input
            id="display-name-input"
            value={inputValue}
            containerClassName="flex-1 w-full max-w-[500px]"
            onChangeText={(text: string) => {
              setDisplayNameStatus('edited');
              if (!text) {
                setDisplayName('');
                setDisplayNameError('Display name cannot be empty');
              } else {
                setDisplayNameError('');
                setDisplayName(text);
              }
            }}
            endIcon={
              displayNameLoading ? (
                <LoadingSpinner
                  size={20}
                  className="fill-theme-primary-600 text-theme-primary-300"
                />
              ) : inputValue !== userInfo?.name ? (
                <SaveIcon
                  size={24}
                  className="bg-theme-primary-600 text-white p-1 rounded-full cursor-pointer"
                  onClick={saveDisplayName}
                />
              ) : undefined
            }
            endIconClassName="mt-2 right-3 flex items-center"
            error={!!displayNameError}
          />
          {displayNameError && (
            <div className="text-theme-danger-600 mb-3">{displayNameError}</div>
          )}
        </div>
        <div className="flex flex-col">
          <div className="text-sm text-theme-neutral-700 font-medium w-28 mb-3">
            Email
          </div>
          <Input
            id="email-input"
            value={userInfo?.email}
            containerClassName="flex-1 w-full max-w-[500px]"
            disabled
            endIcon={<LockIcon size={20} />}
            endIconClassName="mt-2 right-4 text-theme-neutral-400"
            tooltipText="You are not allowed to update your email"
          />
        </div>
      </div>
      {userInfo?.api_key && (
        <div id="api-key" className="flex flex-col pt-6">
          <div className="text-lg font-semibold text-theme-neutral-700 mb-2">
            API Key
          </div>
          <div className="text-sm font-light mb-6">
            API keys allow you to securely access Hyperbolic's services. Please
            protect your key because anyone who has the key has full access to
            your account.
          </div>
          <APIKey
            // text="default"
            apiKey={userInfo?.api_key}
            containerClassName="bg-transparent border-0"
            className="bg-transparent rounded-none py-5 px-0 lg:w-auto border-t border-b border-theme-neutral-200"
            innerClassName="w-96"
            textClassName="w-20"
            additionalButtons={
              <ResetIcon
                className="transition cursor-pointer text-theme-neutral-600 hover:text-theme-primary-600"
                size={19}
                onClick={() =>
                  dispatch(
                    openModal({
                      name: ModalName.ResetApiKey,
                      styles: isMobile
                        ? mobileStyles
                        : { content: { maxWidth: 500 } },
                    })
                  )
                }
              />
            }
          />
        </div>
      )}
      {isMarketplaceV2Enabled && userInfo && (
        <div id="public-key" className="flex flex-col pt-6 mb-8">
          <div className="text-lg font-semibold text-theme-neutral-700 mb-2">
            SSH Public Key
          </div>
          <div className="text-sm font-light mb-6">
            Your public key is required to SSH into instances, and only needs to
            be configured once. Wondering how to get your public key?&nbsp;
            <HyperLink
              to="/support/gpu-rental"
              className="text-theme-neutral-700 underline hover:text-theme-neutral-600"
              state={{ initialQuestion: 3 }}
            >
              Check support
            </HyperLink>
            .
          </div>
          {userInfo?.public_key ? (
            <APIKey
              text="Public Key"
              apiKey={userInfo?.public_key}
              containerClassName="bg-transparent border-0"
              className="bg-transparent rounded-none py-5 px-0 lg:w-auto border-t border-b border-theme-neutral-200"
              innerClassName="w-96"
              textClassName="w-20"
              additionalButtons={
                showCheckmark ? (
                  <FaCircleCheck className="text-theme-primary-600" size={20} />
                ) : (
                  <ResetIcon
                    className="transition cursor-pointer text-theme-neutral-600 hover:text-theme-primary-600"
                    size={19}
                    onClick={() =>
                      dispatch(
                        openModal({
                          name: ModalName.ResetPublicKey,
                          styles: isMobile
                            ? mobileStyles
                            : { content: { maxWidth: 500 } },
                        })
                      )
                    }
                  />
                )
              }
            />
          ) : (
            <PublicKeyDropzone
              onUpload={() => {
                setShowCheckmark(true);
                setTimeout(() => setShowCheckmark(false), 5 * SECOND);
              }}
            />
          )}
        </div>
      )}
      {/* <div className="flex flex-col gap-4 pt-6">
        <div className="text-lg font-semibold text-theme-neutral-700">
          Delete User
        </div>
        <div className="text-sm">
          Once you delete your user, there is no way to retrieve your user data.
        </div>
        <div>
          <Button variant="danger-outline">Delete User</Button>
        </div>
      </div> */}
    </div>
  );
};

export default General;
